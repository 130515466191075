import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Privacy = () => (
  <Layout>
    <Helmet
      bodyAttributes={{
      class: 'legal-page',
      }}
    />
    <section id="legal" className="container container--content">
      <Seo title="Privacy policy" />
      <h1>Privacy policy</h1>
      <a href="http://doctorwhoscience.com/" target="_blank" rel='noopener'>http://doctorwhoscience.com/</a>
      <p>Dated 14th December 2021</p>
      <h3>Our contact details </h3>
      <p> Name: Sarner International Ltd</p>
      <address>
        <p>Address: 5 Princess Mews, Horace Road, Kingston-upon-Thames, Surrey KT1 2SZ</p>
      </address>
      <a href='tel:02084810600'>Phone Number: 020 8481 0600</a>
      <p>E-mail: <a href="dwt@sarner.com">dwt@sarner.com</a></p>
      <h3>How we collect personal information and why we have it</h3>
      <p>This website does not collect personal information.  However, users are given the opportunity to request further information on the “Contact Us” page – using a standard email client.</p>
      <p>We may share emails received with our sales partner – Sanders Exhibition Services Ltd for follow up.  Your email address is not shared with any other parties and is not used for any purpose other than making initial contact.</p>
      <p>Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are: </p>
      <ol>
        <li>Your consent. It is entirely your decision whether you wish to contact us or not.</li>
        <li>We have a contractual obligation.</li>
        <li>We have a legal obligation.</li>
      </ol>
      <h3>How we store your personal information </h3>
      <p>Any emails are stored on our business email servers, and / or the business email servers of our partner as referenced above.</p>
      <h4>Your data protection rights</h4>
      <p>Under data protection law, you have rights including:</p>
      <ul>
        <li><strong>Your right of access</strong> - You have the right to ask us for copies of your personal information. </li>
        <li><strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.  </li>
        <li><strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances. </li>
        <li><strong>Your right to restriction of processing</strong> - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
        <li><strong>Your right to object to processing</strong> - You have the right to object to the processing of your personal information in certain circumstances.</li>
        <li><strong>Your right to data portability</strong> -  You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
        <li><strong>You are not required to pay any charge for exercising your rights.</strong> - If you make a request, we have one month to respond to you.</li>
      </ul>
      <p>Please contact us at dwt@sarner.com, 020 8481 0600 if you wish to make a request.</p>
      <h4>How to complain</h4>
      <p>If you have any concerns about our use of your personal information, you can make a complaint to us at dwt@sarner.com, 020 8481 0600.</p>
      <p>You can also complain to the ICO if you are unhappy with how we have used your data. <br />The ICO’s address:</p>
      <address>
        <p>
          Information Commissioner’s Office
        </p>
        <p>
          Wycliffe House
        </p>
        <p>
          Water Lane
        </p>
        <p>
          Wilmslow
        </p>
        <p>
          Cheshire
        </p>
        <p>SK9 5AF</p>
      </address>
      <p>Helpline number: 0303 123 1113</p>
      <p>ICO website: <a href="https://www.ico.org.uk" target="_blank" target="noopener">https://www.ico.org.uk</a></p>
    </section>
  </Layout>
)

export default Privacy
